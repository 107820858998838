import { defineStore } from 'pinia';
import Cart, { CartAttrs } from '~~/utils/models/Cart';

const getCarts = async function () {
  const carts = await Cart.fetch();
  return carts;
};

const defaultState = () => {
  return {
    count: 0,
    list: [],
    isLoading: false,
  };
};

export const useCartStore = defineStore('cart', {
  state: () => defaultState(),
  actions: {
    addCart(payload: number) {
      this.count += payload;
    },
    async removeCart(cart: CartAttrs) {
      const cart$ = computed(() => new Cart(cart));
      cart$.value.remove();
      this.count -= 1;
    },
    async checkout() {
      await this.refecth();
    },
    async refecth() {
      const { $auth } = useNuxtApp();
      if ($auth.loggedIn) {
        this.isLoading = true;
        await getCarts()
          .then((response) => {
            this.count = response.data.length;
            this.list = [...response.data];
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
});
